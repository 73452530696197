import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../layouts'
import LargeHero from '../../components/elements/largeHero'
import { Body, SizedText, Canon } from '../../components/elements/typography'
import Grid from '../../components/elements/grid'
import SEO from "../../components/elements/seo"

const HistoryPage = () => {
  return (
    <Layout isSubMenuOpen={true} openAccountLink={true}>

      <SEO
        title="About The Claxton Bank - Our History"
        description="Time Changes Everything, Except Our Values.  We have been service Evans and surrounding counties since 1941 - over 75 years"
        location="/about/history"
        ogImg='/images/social-graph/the-claxton-bank.jpg'
      />  

      <LargeHero.Container
        padding="3rem 0 8rem 0"
        background={{
          //backgroundColor: `#b2a08a`,
          backgroundImage: `url("/images/history-hero-bw.jpg")`, 
          backgroundSize: `cover`,
          backgroundPosition: `bottom right`,
          
        }}
        breadcrumbLinks={[
          { path: "/", text: "Home" },
          { path: "/about/", text: "About Us" },
          { path: "/about/history/", text: "Our History" }
        ]}
        styles={{
          paddingBottom: `16rem`,
          overflow: `hidden`,
          '.heroContent': {
            width: `100%`,
          },
          '@media (min-width: 680px)': {
            paddingBottom: `8rem`,
          },
          '@media (min-width: 736px)': {
            backgroundPosition: `center`,
          }
        }}
      >
        <LargeHero.Content styles={{paddingBottom: `2rem`}}>
          <LargeHero.Eyebrow text="About Us" />
          <h1 css={{fontFamily: `Proxima-nova`, textTransform: `uppercase`,  'span': {display: `block`, lineHeight: `1`, padding: `0.5rem 0`}}}>
            <SizedText as="span" smallSize="2.281" largeSize="4.624">Our History</SizedText>
          </h1>
          <SizedText as="p" smallSize="1.602" largeSize="2.027" styleOverrides={{ fontFamily: `source-sans-pro !important`, fontWeight: `300 !important` }}>Serving Claxton & Surrounding Counties for over 75 years.</SizedText>
        </LargeHero.Content>
      </LargeHero.Container>

      <Grid.SafeWidthSection css={{padding: `6rem 0`}}>
        <Grid.Block start={[2, 2, 2, 1, 1, 2]} span={[10,10,10,5, 5, 4]} css={{ 
          marginBottom: `2rem`,
          alignSelf: `center`, 
        }}>
          <StaticImage 
              css={{
                '@media (min-width: 834px)': { display: 'none !important' }, 
                '@media (min-width: 1495px)': { display: 'block !important' } 
              }} 
              src="../../images/history/old-bank-polaroid.png" 
              alt="Polaroid of the original The Claxton Bank Building in 1941"
            />
            <StaticImage 
              css={{
                display: `none !important`,  
                '@media (min-width: 834px)': { display: 'block !important' }, 
                '@media (min-width: 1495px)': { display: 'none !important' } 
              }} 
              src="../../images/history/old-bank-polaroid_cropped.png"
              alt="Polaroid of the original The Claxton Bank Building in 1941"
            />
        </Grid.Block>
        <Grid.Block start={[2,2,2,6]} span={[10,10,10, 5]} css={{alignSelf: `center`}}>
          <Canon>Time Changes Everything, Except Our Values</Canon>
          <Body>
            A lot has happened since 1941 when The Claxton Bank opened for business. The past seven decades have been eventful to say the least. More has happened to change the way mankind lives and works than in any other such period of time.
            At The Claxton Bank, we've seen many changes in Evans County. But through it all, one thing has remained the same, the way we do business. Now, just as it was back then, we are committed to helping our customers manage their "todays" 
            and find their place in tomorrow. Indeed, the more things change, the more they stay the same.
            <ul>
            <li>1962 The Claxton Bank reaches assets of $4.4 million.</li>
            <li>1967 The Claxton Bank opens first drive thru at Main Branch.</li>
            <li>1974 The Claxton Bank opens branch in Bellville.</li>
            <li>1979 The Claxton Bank opens a branch in the Claxton Square Shopping Center.</li>
            <li>1991 The Newton Street Branch, formerly Fulton Federal Savings and Loan, opens in July.</li>
            <li>1996 The Claxton Bank opens first ATM at Main Office.</li>
            <li>2000 The Claxton Bank reports record earnings, deposits, loans and total assets.</li>
            <li>2002 TCB offers Visa© Check Cards.</li>
            <li>2004 TCB adds Online Banking services.</li>
            <li>2011 The Claxton Bank celebrates 70 years!</li>
            <li>2013 New Square Branch is built</li>
            <li>2014 Centennial Commemorative Coin designed by TCB to Congratulate Evans County on 100 years!</li>
            <li>2016 Celebrates 75 years</li>
            <li>2020 TCB Richmond Hill Loan Production Office opens in Richmond Hill, Georgia</li>
            <li>2023 - On April 3, the TCB Richmond Hill Branch opened full service.</li>
          </ul>
          </Body>
          
        </Grid.Block>
      </Grid.SafeWidthSection>

    </Layout>
  )
}

export default HistoryPage

